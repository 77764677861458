<template>
  <div id="openAccount">
    <div class="content_box">
      <div class="inner_max">
        <div class="link_box">
          <ul>
            <li class="active">
              <a @click="getDepositBlacklist()" class="el-button" data-testid="depositFunds">
                {{ $t('common.button.depositFunds') }}
              </a>
              <em class="icon el-icon-arrow-right"></em>
            </li>
            <li>
              <a @click="getWithdrawalBlacklist()" class="el-button" data-testid="withdrawFunds">
                {{ $t('common.button.withdrawFunds') }}
              </a>
              <em class="icon el-icon-arrow-right"></em>
            </li>
            <li>
              <div>
                <a @click.stop.prevent="$router.push('downloads')" class="el-button" data-testid="downloads">
                  {{ $t('common.button.download') }}
                </a>
                <em class="icon el-icon-arrow-right"></em>
              </div>
            </li>
          </ul>
        </div>
        <div class="page-header">{{ $t('menu.openAnAdditLiveAcc') }}</div>
        <OpenLiveAccountTemplate> </OpenLiveAccountTemplate>
      </div>
    </div>
  </div>
</template>

<script>
import OpenLiveAccountTemplate from '@/components/template/OpenLiveAccountTemplate';

export default {
  components: {
    OpenLiveAccountTemplate
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/account/openAccount.scss';
</style>
