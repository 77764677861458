<template>
  <el-card class="openAccount">
    <div class="form_box" v-if="!success">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <ul>
          <li>
            <p class="title">{{ $t('openAdditAcc.chooseTradePlat') }}</p>
            <div class="box box_platform">
              <div class="box_inner">
                <ul>
                  <el-form-item prop="tradingPlatform">
                    <li
                      v-for="item in $platform.openLiveAccount.choosePlatform(regulator)"
                      :key="item.value"
                      @click.prevent.stop="
                        form.tradingPlatform = item.value;
                        form.accountType = null;
                      "
                      :class="{ active: form.tradingPlatform == item.value, popular: item.value === 'mt5' }"
                      :data-testid="item.value"
                    >
                      <img class="mt_img" :src="item.img" alt />
                    </li>
                  </el-form-item>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <p class="title">{{ $t('openAdditAcc.chooseAccType') }}</p>
            <div class="box box_type">
              <div class="box_inner">
                <el-form-item prop="accountType">
                  <ul>
                    <li
                      v-for="(item, index) in liveTypes"
                      :key="index"
                      @click="typeClick(index, item)"
                      :class="{ active: activeType == index }"
                      :data-testid="item.value"
                    >
                      <img class="mt_img" :src="item.img" alt width="111px" height="100%" />
                    </li>
                  </ul>
                  <p v-if="pammFlag" class="accTypeNote">
                    <b>{{ $t('openAdditAcc.pammNote') }}</b>
                  </p>
                  <p v-if="swapFreeFlag" class="accTypeNote">
                    <b>{{ $t('openAdditAcc.swapFreeNote') }}</b>
                  </p>
                </el-form-item>
              </div>
            </div>
          </li>
          <li>
            <p class="title">{{ $t('openAdditAcc.chooseCurrency') }}</p>
            <div class="box box_currency">
              <div class="box_inner">
                <el-form-item prop="currency">
                  <ul>
                    <li
                      v-for="(item, index) in accountCurrencyList"
                      :key="index"
                      @click="currencyClick(index, item)"
                      :class="{ active: activeCurrency == index }"
                      :data-testid="item.currency"
                    >
                      <div class="shadow_inner">
                        <img :src="item.img" @click.prevent.stop="currencyClick(index, item)" alt="" />
                        <p>{{ item.value }}</p>
                      </div>
                    </li>
                  </ul>
                </el-form-item>
              </div>
            </div>
          </li>
        </ul>
        <div class="box">
          <div class="box_inner">
            <p class="title">{{ $t('openAdditAcc.notes') }}</p>
            <el-row class="mt-3">
              <el-col :xs="24" :sm="24" :md="8">
                <el-form-item>
                  <el-input v-model="form.notes" placeholder="Eg. IB/MAM/Server Location"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="tnc">
          <div class="mb-3">
            <el-form-item prop="tnc">
              <el-checkbox
                :label="$t('openAdditAcc.byTicking')"
                name="type"
                v-model="form.tnc"
                data-testid="byTicking"
              ></el-checkbox>
            </el-form-item>
          </div>
          <ul
            class="mb-5"
            dir="ltr"
            v-html="
              $t($platform.openLiveAccount.getAgreeCondition(regulator), {
                platform: $platform.info.fullName,
                regulatorUrl: GLOBAL_DOMAIN_WEBSITE
              })
            "
          ></ul>
        </div>
        <div class="text-center mt-5">
          <el-button class="bg-primary" @click="submitClick" data-testid="submit">
            {{ $t('common.button.submit') }}
          </el-button>
        </div>
      </el-form>
    </div>
    <Result v-else>
      <div v-html="$t('openAdditAcc.succ')"></div>
    </Result>
  </el-card>
</template>

<script>
import Result from '@/components/Result';
import { checkAgreedTNC } from '@/util/validation';
import { apiApplyAdditionalAccount } from '@/resource';
import { countryCodeEnum } from '@/constants/country';

export default {
  name: 'OpenLiveAccount',
  components: { Result },
  data() {
    return {
      activeType: null,
      activeCurrency: null,
      accountCurrencyList: [],
      form: {
        tradingPlatform: 'mt4',
        accountType: null,
        currency: null,
        notes: '',
        tnc: false
      },
      rules: {
        tradingPlatform: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.platErr') }],
        accountType: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.accTypeErr') }],
        currency: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.currError') }],
        tnc: [{ validator: checkAgreedTNC, trigger: 'change' }]
      },
      success: false
    };
  },
  computed: {
    liveTypes() {
      const swapFreeCountries = this.$platform.openLiveAccount.getSwapFreeCountries(this.regulator);
      const swapFreeMenaCountries = this.$platform.openLiveAccount.getSwapFreeMenaCountries(this.regulator);
      const filterLiveType = this.$platform.openLiveAccount.getFilterLiveType(this.regulator);
      const filterMenaLiveType = this.$platform.openLiveAccount.getFilterMenaLiveType(this.regulator);
      const liveType_mt4 = this.$platform.openLiveAccount.chooseLiveType(this.regulator, this.proClientType);
      const liveType_mt5 = this.$platform.openLiveAccount.mt5ChooseType(this.regulator, this.proClientType);
      const hidePAMM = this.form.tradingPlatform === 'mt4' && parseInt(this.countryCode) === countryCodeEnum.CHINA;
      let liveType = this.form.tradingPlatform === 'mt4' ? liveType_mt4 : liveType_mt5;

      // for ib accounts handling only
      let directStpCountries = this.$platform.openLiveAccount.getDirectStpCountries(this.regulator);
      let swapFreeStpCountries = this.$platform.openLiveAccount.getSwapFreeStpCountries(this.regulator);

      if (this.ibClient) {
        // direct stp countries only
        if (directStpCountries.includes(parseInt(this.countryCode))) {
          // swap free stp countries only
          if (swapFreeStpCountries.includes(parseInt(this.countryCode))) {
            // enable ecn for indonesia, turkey
            if ([countryCodeEnum.INDONESIA, countryCodeEnum.TURKEY].includes(parseInt(this.countryCode))) {
              return liveType.filter(item =>
                ['standardSTP', 'swapFreeStandardSTP', 'rawECN', 'swapFreeRawECN', 'PAMM'].includes(item.value)
              );
            }
            return liveType.filter(item => ['standardSTP', 'swapFreeStandardSTP', 'PAMM'].includes(item.value));
          }

          if ([countryCodeEnum.SOUTH_KOREA].includes(parseInt(this.countryCode))) {
            return liveType.filter(item => ['standardSTP', 'rawECN', 'PAMM'].includes(item.value));
          }

          // china only
          if (hidePAMM) {
            return liveType.filter(item => item.value == 'standardSTP');
          } else {
            return liveType.filter(item => ['standardSTP', 'PAMM'].includes(item.value));
          }
        }

        // swap free mena countries only
        if (swapFreeMenaCountries.includes(parseInt(this.countryCode))) {
          return liveType.filter(
            item => filterMenaLiveType.includes(item.value) || ['standardSTP', 'rawECN', 'PAMM'].includes(item.value)
          );
        }

        // other countries
        return liveType.filter(item => ['standardSTP', 'rawECN', 'PAMM'].includes(item.value));
      } else {
        //Hide PAMM account type when countryCode is China and Platform is MT4
        if (hidePAMM) {
          liveType = liveType.filter(item => item.value !== 'PAMM');
        }

        // hide 10 day swap free buttons if not mena countries
        if (!swapFreeMenaCountries.includes(parseInt(this.countryCode))) {
          liveType = liveType.filter(i => !filterMenaLiveType.includes(i.value));
        }

        // hide swap free buttons if not swap free countries
        if (!swapFreeCountries.includes(parseInt(this.countryCode))) {
          return liveType.filter(i => !filterLiveType.includes(i.value));
        }
      }

      return liveType;
    },
    proClientType() {
      return this.$store.state.upgrade.proClientType;
    },
    pammFlag() {
      return this.form.accountType === 'PAMM' ? true : false;
    },
    swapFreeFlag() {
      return this.form.accountType === 'swapFreeStandardSTP' || this.form.accountType === 'swapFreeRawECN'
        ? true
        : false;
    },
    clientClassificationType() {
      return this.$store.state.common.clientClassification;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    ibClient() {
      return this.$store.state.common.ibClient;
    }
  },
  methods: {
    createDemoAccount() {
      let escapeString = this.escapeStringForJSON(this.form.notes);

      return apiApplyAdditionalAccount({
        notes: escapeString,
        accountType: this.form.accountType,
        tradingPlatform: this.form.tradingPlatform,
        currency: this.form.currency,
        tnc: this.form.tnc
      });

      // return apiApplyAdditionalAccount({ ...this.form });
    },
    submitClick() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.createDemoAccount().then(resp => {
            if (resp.data.code === 0) this.success = true;
          });
        }
      });
    },
    getAccountCurrencyList(value) {
      const filterLiveType = this.$platform.openLiveAccount.getFilterLiveType(this.regulator);
      const filterMenaLiveType = this.$platform.openLiveAccount.getFilterMenaLiveType(this.regulator);
      const filterCurrencyType = this.$platform.openLiveAccount.getFilterCurrencyType(this.regulator);
      const currencyList = this.$platform.openLiveAccount.chooseCurrency(this.regulator);

      if (value) {
        // filter account currency type with USD only for swap free account type
        if (filterLiveType.includes(value) || filterMenaLiveType.includes(value)) {
          this.accountCurrencyList = currencyList.filter(i => {
            return filterCurrencyType.includes(i.currency);
          });
        } else {
          this.accountCurrencyList = currencyList;
        }
      } else {
        this.accountCurrencyList = currencyList;
      }
    },
    typeClick(index, item) {
      this.activeType = index;
      this.form.accountType = item.value;
      // if (item.value === 'PAMM') {
      //   this.pammFlag = true;
      // } else {
      //   this.pammFlag = false;
      // }
      this.getAccountCurrencyList(item.value);
    },
    currencyClick(index, item) {
      this.activeCurrency = index;
      this.form.currency = item.currency;
    },
    escapeStringForJSON(string) {
      return string.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }
  },
  mounted() {
    this.getAccountCurrencyList();
  }
};
</script>
